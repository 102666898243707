<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="停车订单详情"
    :width="1020"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button  key='continue' @click="doSubmit" type="primary"  >审核记录</a-button>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
    </template>
    <div style="max-height: 450px; overflow-y: scroll">
      <a-space direction="horizontal" align="start">
        <div style="width: 600px">
          <div>
            <a-descriptions bordered size="small" :column="2">
              <a-descriptions-item label="车场名称">{{
                parkingRecordVo.parkingLotName
              }}</a-descriptions-item>
              <a-descriptions-item label="泊位号">{{
                parkingRecordVo.slotNum
              }}</a-descriptions-item>
              <a-descriptions-item label="车牌号">{{
                parkingRecordVo.plateNumber
              }}</a-descriptions-item>
              <a-descriptions-item label="停车时长">{{
                getParkingTime(parkingRecordVo.parkingTime)
              }}</a-descriptions-item>
              <a-descriptions-item label="进场时间">{{
                parkingRecordVo.inTime
              }}</a-descriptions-item>
              <a-descriptions-item label="离场时间">{{
                parkingRecordVo.outTime
              }}</a-descriptions-item>
              <a-descriptions-item label="应付金额">{{
                money(parkingRecordVo.shouldPayAmount) + "元"
              }}</a-descriptions-item>
              <a-descriptions-item label="支付状态">
                <a-tag v-if="parkingRecordVo.payState == 0" color="red">待支付</a-tag>
                <a-tag v-if="parkingRecordVo.payState == 1" color="orange">已预缴</a-tag>
                <a-tag v-if="parkingRecordVo.payState == 2" color="success">已全缴</a-tag>
              </a-descriptions-item>
              <a-descriptions-item label="优惠金额">{{
                  money(parkingRecordVo.discountAmount) + "元"
                }}</a-descriptions-item>
              <a-descriptions-item label="优惠额度">{{
                  parkingRecordVo.couponValue + "折"
                }}</a-descriptions-item>
              <a-descriptions-item label="实付金额">{{
                money(parkingRecordVo.paidAmount) + "元"
              }}</a-descriptions-item>
              <a-descriptions-item label="停车状态">
                <a-tag v-if="parkingRecordVo.state == -1" color="default">无效作废</a-tag>
                <a-tag v-if="parkingRecordVo.state == 0" color="orange">在停</a-tag>
                <a-tag v-if="parkingRecordVo.state == 1" color="blue">已出场</a-tag>
                <a-tag v-if="parkingRecordVo.state == 2" color="success">已完成</a-tag>
                <a-tag v-if="parkingRecordVo.state == 3" color="red">图片待录入</a-tag>
              </a-descriptions-item>
              <a-descriptions-item label="待付金额">{{
                  money(parkingRecordVo.oweAmount) + "元"
                }}</a-descriptions-item>
              <a-descriptions-item label="联系电话">{{
                parkingRecordVo.phone
              }}</a-descriptions-item>
            </a-descriptions>
          </div>
        </div>

        <div style="width: 350px">
          <a-descriptions layout="vertical" size="small" bordered>
            <a-descriptions-item label="进场图片"
              ><a-image :width="120" :src="parkingRecordVo.inPic"
            /></a-descriptions-item>
            <a-descriptions-item label="出场图片"
              ><a-image :width="120" :src="parkingRecordVo.outPic"
            /></a-descriptions-item>
          </a-descriptions>
        </div>
      </a-space>

      <a-card title="在场记录抓拍图">
        <div
          v-if="
            !parkingRecordVo.snapPics || parkingRecordVo.snapPics.length == 0
          "
        >
          <a-empty />
        </div>
        <v-else>
          <a-row>
            <a-image-preview-group>
              <a-col
                :span="6"
                v-for="(item, index) in parkingRecordVo.snapPics"
                :key="index"
              >
                <a-card :title="item.picTime">
                  <div style="text-align: center">
                    <a-image :width="120" :src="item.picUrl" />
                  </div>
                </a-card>
              </a-col>
            </a-image-preview-group>
          </a-row>
          <a-row style="padding-top: 20px">
            <a-pagination
              v-model:current="page"
              v-model:page-size="pageSize"
              :total="total"
              :show-total="total => `${total}条数据`"
              @change="currentChange"
            >
            </a-pagination>
          </a-row>
        </v-else>
      </a-card>
    </div>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useMessage from "@/hooks/useMessage";
import { detail } from "@/api/info/parkingRecordApi";
import useParkingRecord from "../../../../hooks/Info/useParkingRecord";
import { listSnapImages } from "../../../../api/info/parkingRecordApi";
import { auditParkingRecord } from "../../../../api/info/payRecordApi";

export default {
  name: "Detail",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    let disabled = ref(false);
    const { parkingRecordVo, money, timeF, getParkingTime } =
      useParkingRecord();

    const total = ref(0);
    const page = ref(1);
    const pageSize = ref(4);
    const currentChange = () => {
      getListSnapImages(page.value, pageSize.value);
    };
    const { showErr,showConfirm, showLoading, closeLoading, showTipMessage } = useMessage();
    const visible = ref(true);
    let refresh = false;

    const afterClose = () => {
      emit("close", refresh);
    };

    const getWidth = picLength => {
      return 100 / picLength + "%";
    };
    onMounted(async () => {
      getListSnapImages(page.value, pageSize.value);
      try {
        let r2 = await detail(props.id);
        parkingRecordVo.parkingLotName = r2.data.parkingLotName;
        parkingRecordVo.slotNum = r2.data.slotNum;
        parkingRecordVo.plateNumber = r2.data.plateNumber;
        parkingRecordVo.inTime = r2.data.inTime;
        parkingRecordVo.outTime = r2.data.outTime;
        parkingRecordVo.state = r2.data.state;
        parkingRecordVo.parkingTime = r2.data.parkingTime;
        parkingRecordVo.nickName = r2.data.nickName;
        parkingRecordVo.payState = r2.data.payState;
        parkingRecordVo.shouldPayAmount = r2.data.shouldPayAmount;
        parkingRecordVo.paidAmount = r2.data.paidAmount;
        // parkingRecordVo.snapPics = r2.data.snapPics;
        parkingRecordVo.discountAmount = r2.data.discountAmount;
        parkingRecordVo.couponValue = r2.data.couponValue;
        parkingRecordVo.inPic = r2.data.inPic;
        parkingRecordVo.outPic = r2.data.outPic;
        parkingRecordVo.phone="";
        parkingRecordVo.oweAmount=r2.data.oweAmount;
        r2.data.userVos.forEach(item => {
          parkingRecordVo.phone = parkingRecordVo.phone+item.phone + ",";

        });

        parkingRecordVo.phone = parkingRecordVo.phone
          ? parkingRecordVo.phone.substr(0, parkingRecordVo.phone.length - 1)
          : "";
      } catch (e) {
        await showErr(e);
      }
    });
    const getListSnapImages = async (page, pageSize) => {
      try {
        let res = await listSnapImages(props.id, page, pageSize);
        parkingRecordVo.snapPics = res.data;

        total.value = res.total;
      } catch (e) {
        showErr(e);
      }
    };

    const doSubmit = async () => {
      let action = await showConfirm("是否提交审核?");
      if (action) {
        disabled.value = true;
        await showLoading();
        try {
          await auditParkingRecord(props.id);
          await closeLoading();
          await showTipMessage("提交成功");
          refresh = true;
          visible.value = false;
        } catch (e) {
          await closeLoading();
          await showErr(e);
          disabled.value = false;
        }
      }
    };

    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      parkingRecordVo,
      money,
      timeF,
      getParkingTime,
      getWidth,
      getListSnapImages,

      total,
      page,
      pageSize,
      currentChange,
      doSubmit,
    };
  },
};
</script>

<style scoped></style>
